<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>Select bench to display</v-card-title>
          <v-card-subtitle>Or in the righthand side, select a skill to activate the bench on.</v-card-subtitle>
          <v-card-text>
            <v-row>
              <v-col cols="2">
                <v-autocomplete v-model="column1" :items="talentData" chips item-value="id" item-text="title" label="Column 1" clearable></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="column2" :items="talentData" chips item-value="id" item-text="title" label="Column 2" clearable></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="column3" :items="talentData" chips item-value="id" item-text="title" label="Column 3" clearable></v-autocomplete>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="column4" :items="talentData" chips item-value="id" item-text="title" label="Column 4" clearable></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-spacer></v-spacer>
              </v-col>
              <v-col cols="2">
                <v-autocomplete v-model="skillSelected" :items="skillList" chips item-value="id" item-text="title" label="Select skill to activate" clearable></v-autocomplete>
              </v-col>
              <v-col cols="1">
                <v-btn @click="activateSkill(skillSelected)" color="primary" small>Activate</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card :loading="loading" flat tile>
          <v-card-text>
            <v-row>
              <v-col cols="3">
                <h4 style="justify-content: center">{{ column1 ? talentData.find(t => t.id === column1).title : "Select Column 1" }} ({{column1 ? talentData.find(t => t.id === column1).bench.length : ""}})</h4>
                <ScreeningCard
                    :submission-data="column1 ? talentData.find(t => t.id === column1).bench : []"
                    :selectedSkills="talentData.find(t => t.id === column1) ? [talentData.find(t => t.id === column1).title] : []"
                    :benchCard="true"
                    update-string="screening"
                    @confirmDeleteTalentSubmission="setDeleteItem"
                    @scoreItem="setScoreItem"
                    @notesItem="setNotesItem"
                    @linkTagItem="setTagItem"
                    @linkBenchItem="setBenchItem"
                    @stageUpdated="submissionStageUpdated"
                    @jsonViewer="setJsonViewerItem"
                    @colorUpdated="colorUpdated"
                    @closeTalentInit="setCloseTalentItem"
                >
                </ScreeningCard>
              </v-col>
              <v-col cols="3">
                <h4 style="justify-content: center">{{ column2 ? talentData.find(t => t.id === column2).title : "Select Column 2" }} ({{column2 ? talentData.find(t => t.id === column2).bench.length : ""}})</h4>
                <ScreeningCard
                    :submission-data="column2 ? talentData.find(t => t.id === column2).bench : []"
                    :selectedSkills="talentData.find(t => t.id === column2) ? [talentData.find(t => t.id === column2).title] : []"
                    :benchCard="true"
                    update-string="skill test"
                    @confirmDeleteTalentSubmission="setDeleteItem"
                    @scoreItem="setScoreItem"
                    @notesItem="setNotesItem"
                    @linkTagItem="setTagItem"
                    @linkBenchItem="setBenchItem"
                    @stageUpdated="submissionStageUpdated"
                    @jsonViewer="setJsonViewerItem"
                    @colorUpdated="colorUpdated"
                    @closeTalentInit="setCloseTalentItem"
                >
                </ScreeningCard>
              </v-col>
              <v-col cols="3">
                <h4 style="justify-content: center">{{ column3 ? talentData.find(t => t.id === column3).title : "Select Column 3" }} ({{column3 ? talentData.find(t => t.id === column3).bench.length : ""}})</h4>
                <ScreeningCard
                    :submission-data="column3 ? talentData.find(t => t.id === column3).bench : []"
                    :selectedSkills="talentData.find(t => t.id === column3) ? [talentData.find(t => t.id === column3).title] : []"
                    :benchCard="true"
                    update-string="first interview"
                    @confirmDeleteTalentSubmission="setDeleteItem"
                    @scoreItem="setScoreItem"
                    @notesItem="setNotesItem"
                    @linkTagItem="setTagItem"
                    @linkBenchItem="setBenchItem"
                    @stageUpdated="submissionStageUpdated"
                    @jsonViewer="setJsonViewerItem"
                    @colorUpdated="colorUpdated"
                    @closeTalentInit="setCloseTalentItem"
                >
                </ScreeningCard>
              </v-col>
              <v-col cols="3">
                <h4 style="justify-content: center">{{ column4 ? talentData.find(t => t.id === column4).title : "Select Column 4" }} ({{column4 ? talentData.find(t => t.id === column4).bench.length : ""}})</h4>
                <ScreeningCard
                    :submission-data="column4 ? talentData.find(t => t.id === column4).bench : []"
                    :selectedSkills="talentData.find(t => t.id === column4) ? [talentData.find(t => t.id === column4).title] : []"
                    :benchCard="true"
                    update-string="onboarding"
                    @confirmDeleteTalentSubmission="setDeleteItem"
                    @scoreItem="setScoreItem"
                    @notesItem="setNotesItem"
                    @linkTagItem="setTagItem"
                    @linkBenchItem="setBenchItem"
                    @stageUpdated="submissionStageUpdated"
                    @jsonViewer="setJsonViewerItem"
                    @colorUpdated="colorUpdated"
                    @closeTalentInit="setCloseTalentItem"
                >
                </ScreeningCard>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-dialog
          v-if="deleteItem"
          v-model="confirmDeleteDialog"
          @click:outside="toggleConfirmDeleteDialog"
          max-width="60vh"
          :persistent="deleteLoading"
      >
        <v-card>
          <v-card-title>Are you sure you want to delete this submission?</v-card-title>
          <v-card-subtitle>It should only be deleted due to being a duplicate, or similar.</v-card-subtitle>
          <v-card-actions>
            <v-btn color="danger" @click="deleteSubmission" :loading="deleteLoading">Yes, delete it</v-btn>
            <v-btn color="primary" @click="toggleConfirmDeleteDialog" :loading="deleteLoading">No, keep it</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="notesItem"
          v-model="notesDialog"
          @click:outside="toggleNotesDialog"
          max-width="60vh"
          :persistent="notesLoading"
      >
        <v-card>
          <v-card-title>Notes for this submission</v-card-title>
          <v-card-subtitle>Internal notes can be left here.</v-card-subtitle>
          <v-card-text>
            <textarea style="border-color: black;border-style: solid" rows="8" cols="75" v-model="notesItem.notes"></textarea>
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" @click="toggleNotesDialog" :loading="notesLoading">Close (without saving, or click outside)</v-btn>
            <v-btn color="primary" :loading="notesLoading" @click="updateNotesSubmission(notesItem)">Save & Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
          v-if="scoreItem"
          v-model="scoreDialog"
          @click:outside="toggleScoreDialog"
          max-width="60vh"
          :persistent="scoreLoading"
      >
        <v-card>
          <v-card-title>Score card</v-card-title>
          <v-card-subtitle>Update the scores for this submission here.</v-card-subtitle>
          <v-card-text>
            <v-text-field type="number" label="Slashdev/ Score" v-model="scoreItem.slashdev_score"></v-text-field>
            <v-text-field type="number" label="Skill Test Score" v-model="scoreItem.skill_test_score"></v-text-field>
            <v-text-field type="number" label="English Level" v-model="scoreItem.english_level"></v-text-field>
            <v-text-field type="number" label="Contract Rate" v-model="scoreItem.rate"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" @click="toggleScoreDialog" :loading="scoreLoading">Close (without saving, or click outside)</v-btn>
            <v-btn color="primary" :loading="scoreLoading" @click="updateScoreSubmission(scoreItem)">Save & Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="tagItem"
          v-model="tagDialog"
          @click:outside="toggleTagDialog"
          max-width="60vh"
          :persistent="tagLoading"
      >
        <v-card>
          <v-card-title>Tag card</v-card-title>
          <v-card-subtitle>Update the tags for this submission here.</v-card-subtitle>
          <v-card-text>
            <v-autocomplete :items="tagList" item-text="tag" item-value="id" multiple label="Tag" chips v-model="tagSelected"></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" @click="toggleTagDialog" :loading="tagLoading">Close (without saving, or click outside)</v-btn>
            <v-btn color="primary" :loading="tagLoading" @click="updateTagSubmission(tagItem)">Save & Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
          v-if="benchItem"
          v-model="benchDialog"
          @click:outside="toggleBenchDialog"
          max-width="60vh"
          :persistent="benchLoading"
      >
        <v-card>
          <v-card-title>Bench card</v-card-title>
          <v-card-subtitle>Update the bench for this submission here.</v-card-subtitle>
          <v-card-text>
            <v-autocomplete :items="benchList" item-text="title" item-value="id" multiple label="Bench" chips v-model="benchSelected"></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn color="danger" @click="toggleBenchDialog" :loading="benchLoading">Close</v-btn>
            <v-btn color="primary" :loading="benchLoading" @click="updateBenchSubmission(benchItem)">Save & Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <MatchProfileToTalentSubmissionDialog
          v-if="closeTalentItem"
          :dialog="closeTalentDialog"
          :talent-submission-data="closeTalentItem"
          @closeTalentDialogClosed="toggleCloseTalentDialog"
          @submissionClosed="submissionClosed"
      ></MatchProfileToTalentSubmissionDialog>

      <TableViewJsonSelectedRowColumnDialog
          v-if="jsonViewerItem"
          :json="jsonViewerItem"
          :dialog="jsonViewerDialog"
          @TableViewSelectedRowColumnDialogClosed="toggleJsonViewerDialog"
      ></TableViewJsonSelectedRowColumnDialog>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import ScreeningCard from "@/components/lists/components/ScreeningCard";
import TableViewJsonSelectedRowColumnDialog from "@/components/tables/dialogues/TableViewJsonSelectedRowColumnDialog";
import MatchProfileToTalentSubmissionDialog from "./components/MatchProfileToTalentSubmissionDialog";

export default {
  name: "SkillBenchList",
  components: {MatchProfileToTalentSubmissionDialog, TableViewJsonSelectedRowColumnDialog, ScreeningCard},
  computed: {

  },

  data() {
    return {
      testData: [
        {
          id: 1,
          name: "Hey 1",
        },
        {
          id: 2,
          name: "Hey 2",
        },
        {
          id: 3,
          name: "Hey 3",
        },
      ],
      talentData: [],
      loading: true,
      confirmDeleteDialog: false,
      deleteItem: null,
      deleteLoading: false,
      notesDialog: false,
      notesItem: null,
      notesLoading: false,
      scoreDialog: false,
      scoreItem: null,
      scoreLoading: false,
      tagList: [],
      tagSelected: [],
      tagFilter: "",
      tagDialog: false,
      tagItem: null,
      tagLoading: false,
      benchList: [],
      benchSelected: [],
      benchFilter: "",
      benchDialog: false,
      benchItem: null,
      benchLoading: false,
      jsonViewerDialog: false,
      jsonViewerItem: null,
      closeTalentDialog: false,
      closeTalentItem: null,
      searchTerm: "",
      skillList: [],
      skillSelected: [],
      campaignSelected: [],
      countriesSelected: [],
      timezonesSelected: [],
      yearsOfExperienceSelected: null,
      hasSkillTestScore: false,
      column1: null,
      column2: null,
      column3: null,
      column4: null,
    }
  },

  mounted() {
    this.getBenchData()
    this.getTags()
    this.getSkills()
    this.getAvailableBenches()
  },

  methods: {
    toggleConfirmDeleteDialog() {
      this.confirmDeleteDialog = !this.confirmDeleteDialog
    },

    toggleNotesDialog() {
      this.notesDialog = !this.notesDialog
    },

    toggleScoreDialog() {
      this.scoreDialog = !this.scoreDialog
    },

    toggleTagDialog() {
      this.tagDialog = !this.tagDialog
    },

    toggleBenchDialog() {
      this.benchDialog = !this.benchDialog
    },

    toggleJsonViewerDialog() {
      this.jsonViewerDialog = !this.jsonViewerDialog
    },

    toggleCloseTalentDialog() {
      this.closeTalentDialog = !this.closeTalentDialog
    },

    setNotesItem(item) {
      this.notesItem = item
      this.toggleNotesDialog()
    },

    setDeleteItem(item) {
      this.deleteItem = item
      this.toggleConfirmDeleteDialog()
    },

    setScoreItem(item) {
      this.scoreItem = item
      this.toggleScoreDialog()
    },

    setTagItem(item) {
      this.tagItem = item
      this.tagSelected = []
      item.tags.map(t => this.tagSelected.push(t.id))
      this.toggleTagDialog()
    },

    setBenchItem(item) {
      this.benchItem = item
      this.benchSelected = []
      item.bench.map(t => this.benchSelected.push(t.id))
      this.toggleBenchDialog()
    },

    setJsonViewerItem(item) {
      this.jsonViewerItem = item
      this.toggleJsonViewerDialog()
    },

    setCloseTalentItem(item) {
      this.closeTalentItem = item
      this.toggleCloseTalentDialog()
    },

    submissionStageUpdated(updatedEvent) {
      this.talentData = this.talentData.filter(t => t.id !== updatedEvent.id)
      this.talentData.push(updatedEvent)
    },

    colorUpdated(item) {
      let obj = this.talentData.find((t) => t.id === item.id)
      obj.color = item.color
    },

    submissionClosed(item) {
      let obj = this.talentData.find((t) => t.id === item)
      obj.stage = "closed"
      this.toggleCloseTalentDialog()
    },

    tagUpdated(item) {
      let obj = this.talentData.find((t) => t.id === item.id)
      obj.tags = item.tags
    },

    benchUpdated(item) {
      let obj = this.talentData.find((t) => t.id === item.id)
      obj.bench = item.bench
    },

    submissionDeleted(item) {
      this.talentData = this.talentData.filter(t => t.id !== item.id)
    },

    deleteSubmission() {
      let self = this
      self.deleteLoading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
        axios.delete(`${this.$store.state.apiUrl}/api/talent-submission/${self.deleteItem.id}`, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "Submission deleted",
                color: "info"
              })
              self.submissionDeleted(self.deleteItem)
              self.deleteLoading = false
              self.toggleConfirmDeleteDialog()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Submission failed to delete: " + response.response.data,
                color: "danger"
              })
              self.deleteLoading = false
              self.toggleConfirmDeleteDialog()
            })
      });
    },

    updateNotesSubmission(item) {
      let self = this
      self.notesLoading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(response => {
        console.log(response)
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${item.id}`, {"notes": self.notesItem.notes}, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "Note updated",
                color: "info"
              })
              self.notesLoading = false
              self.toggleNotesDialog()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Note failed to save: " + response.response.data,
                color: "danger"
              })
              self.notesLoading = false
              self.toggleNotesDialog()
            })
      });
    },

    updateScoreSubmission(item) {
      let self = this
      self.scoreLoading = true

      let whatToUpdate = {}

      if (item.skill_test_score) {
        whatToUpdate.skill_test_score = item.skill_test_score
      }

      if (item.slashdev_score) {
        whatToUpdate.slashdev_score = item.slashdev_score
      }

      if (item.english_level) {
        whatToUpdate.english_level = item.english_level
      }

      if (item.rate) {
        whatToUpdate.rate = item.rate
      }

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${item.id}`, whatToUpdate, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "Score updated",
                color: "info"
              })
              self.scoreLoading = false
              self.toggleScoreDialog()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Score failed to save: " + response.response.data,
                color: "danger"
              })
              self.scoreLoading = false
              self.toggleScoreDialog()
            })
      });
    },

    updateTagSubmission(item) {
      let self = this
      self.tagLoading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
        axios.put(`${this.$store.state.apiUrl}/api/talent-submission/${item.id}`, { tags: self.tagSelected }, this.$store.state.headers)
            .then(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Tag updated",
                color: "info"
              })
              self.tagLoading = false
              self.tagUpdated(response.data)
              self.toggleTagDialog()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Tag failed to save: " + response.response.data,
                color: "danger"
              })
              self.tagLoading = false
              self.toggleTagDialog()
            })
      });
    },

    getTags() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/tags`, this.$store.state.headers)
          .then(function (response) {
            self.tagList = response.data
          });
    },

    updateBenchSubmission(item) {
      let self = this
      self.benchLoading = true

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
        axios.put(`${this.$store.state.apiUrl}/api/bench/${item.id}`, { bench: self.benchSelected }, this.$store.state.headers)
            .then(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Bench updated",
                color: "info"
              })
              self.benchLoading = false
              self.benchUpdated(response.data)
              self.toggleBenchDialog()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Bench failed to save: " + response.response.data,
                color: "danger"
              })
              self.benchLoading = false
              self.toggleBenchDialog()
            })
      });
    },

    getAvailableBenches() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/available-skill-bench`, this.$store.state.headers)
          .then(function (response) {
            self.benchList = response.data
          });
    },

    getBenchData() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/bench`, this.$store.state.headers)
          .then(function (response) {
            self.talentData = response.data
            self.loading = false
          });
    },

    // pushSkillBenchData(column, skillId) {
    //   let self = this
    //   axios.get(`${this.$store.state.apiUrl}/api/bench/skill`, this.$store.state.headers)
    //       .then(function (response) {
    //         self.loading = false
    //       });
    // },

    activateSkill(skillId) {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/sanctum/csrf-cookie`).then(() => {
        axios.put(`${this.$store.state.apiUrl}/api/bench-activate/${skillId}`, {}, this.$store.state.headers)
            .then(function () {
              self.$store.commit("displaySnackbar", {
                message: "Skill activated! Go back to talent submissions page to add people to the bench.",
                color: "info"
              })
              self.getBenchData()
            })
            .catch(function (response) {
              self.$store.commit("displaySnackbar", {
                message: "Skill did NOT activate: " + response.response.data,
                color: "danger"
              })
            })
      });
    },

    getSkills() {
      let self = this

      axios.get(`${this.$store.state.apiUrl}/api/skills`, this.$store.state.headers)
          .then(function (response) {
            self.skillList = response.data
          });
    },
  }
}
</script>

<style scoped>

</style>
